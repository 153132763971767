<template>
  <!-- <div class="hello">
    <v-btn @click="$router.push('/hehe')"> to hehe</v-btn>
    <v-btn @click="login">获取</v-btn>
    <v-btn @click="$cookies.remove('access_token')">清除</v-btn>
  </div>-->
  <v-layout max-width>
    <div class="login flex-center flex-column max-width" style="height:100vh;">
      <div class="flex-1 flex-column flex-center max-width position-relative" style=" z-index:2;">
        <div
          class="flex-justify-between"
          style="min-height:550px;box-shadow:0 0 6px 1px #ddd;background:#fff;"
        >
          <div
            class="grey lighten-5 flex-shrink-0 radius-0 flex-column flex-center py-4 px-6"
            style="border-right: 1px dashed #eee !important;width:400px;padding:16px 24px;"
          >
            <img class src="@/assets/logo3.png" width="200">
            <div
              class="mb-2 pb-2 mt-2 flex-center"
              style="margin:8px 0;padding-bottom:8px; border-bottom: 1px dashed rgb(221, 221, 221);"
            >
              <img class src="@/assets/logo4.png" width="300">
            </div>
            <div
              class="font-weight-bold title mb-4 mt-6"
              style="margin-bottom:8px;margin-bottom: 16px;font-weight:700;font-size:1.25rem;line-height:2rem;"
            >CTMS后台管理系统</div>
            <p
              class="body-2 grey--text"
              style="text-indent: 2em;font-size:1em;"
            >以临床试验项目为中心进行延伸拓展，包括FTE、项目中心管理、质量、受试者、报告、SAE、文档等管理，使管理者方便及时的掌握试验进展情况。</p>
            <!-- <div
            class="body-2 mt-12 grey--text"
            style="margin-top:48px;font-size:0.85rem;"
          >(测试账号：admin/123456)</div> -->
            <!-- <div
              class="body-2 mt-12 grey--text"
              style="margin-top:48px;font-size:0.85rem;"
            >v1.2.06.22-beta-20200101</div> -->
          </div>
          <div
            class="py-4 px-10 flex-shrink-0 flex-center"
            style="padding:32px 40px;min-width:500px;"
            min-width="500"
          >
            <div class="flex-center flex-1" style>
              <form v-if="loginType === '1'" class="flex-column max-width" @submit.prevent="submit">
                <!-- margin-bottom:8px; -->
                <div
                  class="headline font-weight-bold"
                  style="font-weight:700;font-size:1.5rem;line-height:2rem;margin-bottom:8px;"
                >欢迎您，登录</div>
                <div
                  class="body-2 mb-8 grey--text flex-y-center flex-justify-between"
                  style="margin-bottom:32px;"
                >
                  <span>请填写登录信息</span>
                </div>
                <div>
                  <div
                    class="flex-justify-between default-input"
                    style="margin-bottom:28px;height:56px;"
                  >
                    <!-- <a-input required class="flex-1  subtitle-1 max-height" placeholder="用户名"
                  style="height:100%;border:none;background:rgba(0, 0, 0, 0.06);padding-left:24px;"  v-model="form.username" @pressEnter="submit"/>-->
                    <v-icon class=" flex-0 ml-1" color=" primary">mdi-account-outline</v-icon>
                    <input
                      v-model="form.username"
                      required
                      class="flex-1 subtitle-1 max-height"
                      placeholder="用户名"
                      style="height:100%;padding-left:10px;"
                    >
                  </div>
                  <div
                    class="flex-justify-between default-input"
                    style="height:56px;margin-bottom:28px;"
                  >
                    <!-- <a-input required class="flex-1 subtitle-1" type="password" placeholder="密码"
                  style="height:100%;border:none;background:rgba(0, 0, 0, 0.06);padding-left:24px;" v-model="form.password" @pressEnter="submit"/>-->
                    <v-icon class=" flex-0 ml-1" color=" primary">mdi-lock-outline</v-icon>
                    <input
                      v-model="form.password"
                      required
                      class="flex-1 subtitle-1"
                      type="password"
                      placeholder="密码"
                      style="height:100%;padding-left:10px;"
                    >
                  </div>
                  <div class=" flex-justify-between">
                    <div
                      class=" flex-1 flex-justify-between default-input"
                      style="height:56px;margin-bottom:28px;"
                    >
                      <v-icon class=" flex-0 ml-1" color=" primary">mdi-shield-check-outline</v-icon>
                      <input
                        v-model="form.captcha"
                        class="flex-1 subtitle-1"
                        type="text"
                        placeholder="验证码"
                        style="height:100%;padding-left:10px;"
                      >

                    </div>
                    <div class=" flex-0 flex-shrink-0 ml-4" style="height:56px;width:120px" @click="getRandomImage">
                      <img width="100%" height="100%" :src="randomImage">
                    </div>
                  </div>
                  <div class="flex-justify-end max-width mb-5">
                    <!-- <v-checkbox
                      v-model="form.remember_me"
                      class="pt-0 mt-0"
                      style="margin-top:0"
                      label="记住密码"
                    /> -->
                    <!-- <u class="flex-shrink-0 cursor_pointer" style="color:#1890ff" @click="$router.push('/forgetPassword')">忘记密码？</u> -->
                    <router-link class="flex-shrink-0 primary--text" to="/forgetPassword">忘记密码？</router-link>
                  </div>
                  <div
                    class="flex-justify-between "
                    style="background:rgba(0, 0, 0, 0.06);height:44px;"
                  >
                    <!-- <a-button :loading='btnLoading' @click="submit" class="flex-1" style="height:100%;border:none;background:#1890ff;padding-left:24px;color:#fff;" >登录</a-button> -->
                    <v-btn
                      :loading="btnLoading"
                      depressed
                      class="flex-1"
                      style="height:100%;border:none;background:#1890ff;padding-left:24px;color:#fff;"
                      type="submit"
                    >登录</v-btn>
                  </div>
                </div>
              </form>
              <v-form v-else-if="loginType==='2'" ref="form" v-model="valid" class="max-width" style="max-width: 400px;" @submit.prevent="resetSubmit">
                <div class="text-h5 font-weight-bold mb-2">修改密码</div>
                <div class="grey--text" style="margin-bottom: 30px">请填写信息</div>
                <span class=" red--text">{{ backMessage }}</span>
                <div>
                  <v-text-field v-model="reset.oldPwd" required :rules="[v => !!v || '请输入原密码...']" prepend-icon="mdi-lock" placeholder="原密码" type="password" />
                </div>
                <div>
                  <v-text-field v-model="reset.password" required :rules="[v => !!v || '请输入新密码...']" prepend-icon="mdi-lock" placeholder="新密码" type="password" />
                </div>
                <div>
                  <v-text-field v-model="reset.confirmPassword" required :rules="[v => !!v || '请输入确认密码...',v => v===reset.password || '新密码与确认密码不一致...']" prepend-icon="mdi-lock" placeholder="确认新密码" type="password" />
                </div>
                <div class="flex-center pt-8">
                  <v-btn
                    class="mr-4"
                    depressed
                    rounded
                    width="100"
                    @click="loginType='1'"
                  >返回登录</v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    rounded
                    :loading="btnLoading"
                    width="120"
                    type="submit"
                  >确认修改</v-btn>
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import { mixinList } from './mixinList'
export default {
  name: 'Login',
  mixins: [mixinList],
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
      loginType: '1', // '1'登陆，'2'首次登陆修改密码
      form: {
        username: '',
        password: '',
        remember_me: false,
        captcha: '',
        checkKey: ''
      },
      btnLoading: false,
      time: '',
      backMessage: '',
      valid: true,
      reset: {
        oldPwd: '',
        username: '',
        password: ''
      }
    }
  },
  mounted() {
    this.getRandomImage()
  },
  methods: {
    // login(){
    //   console.log('a')
    //       this.$http.post(this.$api.login,{"username":"xuzhaosi","password":"Jeeyor2020","remember_me":true}).then(res=>{
    //       this.$cookies.set('access_token',res.data.access_token)
    //       console.log(this.$cookies.get('access_token'))
    //       console.log(res)
    //       }).catch(error=>{
    //       console.log(error)
    //       })
    // },
    async submit() {
      this.btnLoading = true
      this.$http.post(this.$api.login, { data: { ...this.form, sceneFlag: '2' }}).then(res => {
        if (res.code === 0 || res.code === 200) {
          // this.$cookies.set('access_token',res.result.token)
          localStorage.setItem('access_token', res.result.token)

          // this.$cookies.set('access_token',res.result.token,{expire:7})
          //

          localStorage.setItem('userInfo', JSON.stringify(res.result.userInfo))
          return this.$router.replace({ path: '/' })
        }
      }).catch((res) => {
        if (res && res.code) {
          if (res.code === 501) { // 首次登录要修改密码
            this.reset.username = this.form.username
            this.backMessage = res.message || ''
            this.loginType = '2'
            this.$nextTick(() => {
              this.$refs.form && this.$refs.form.reset()
            })
          } else if (res.code === 503) {
            this.$router.replace('/forgetPassword')
          } else {
            this.getRandomImage()
          }
        } else {
          this.getRandomImage()
        }
        this.btnLoading = false
      })
    },
    // 确认修改密码
    async resetSubmit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true
        this.$http.post(this.$api.changePassword, { data: this.reset, type: 'application/x-www-form-urlencoded' }).then(res => {
          this.$message.success(res.result)
          this.getRandomImage() // 重新获取验证码
          this.form.password = ''
          this.loginType = '1' // 返回登陆
          this.btnLoading = false
        }).catch(() => {
          this.btnLoading = false
        })
      } else {
        return false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.login {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      filter: blur(0);
      background-position: center center;
      background-size: cover;
      background-image: url("../../assets/login-background1.jpg");
      /*background-color: #031337;*/
      transition: all .75s ease;
    }

    &::before {
      filter: blur(3px);
    }
  }

  .login-form {
    width: 600px;
    /*width: 100%;*/
    padding: 0 16px;
    z-index: 2;
  }
.login-main {
  min-width: 260px;
  width: 500px;
  margin: 0 auto;
  padding: 20px 40px;
  background-color: #fff;
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
    line-height: 40px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
