export const mixinList = {
  data() {
    return {
      randomImage: ''
    }
  },
  methods: {
    // 获取校验码
    getRandomImage() {
      this.form.checkKey = new Date().getTime()
      this.$http.get(this.$api.randomImage + '/' + this.form.checkKey, {}).then(res => {
        if (res.code === 0) {
          this.randomImage = res.result || ''
        }
      })
    }
  }
}
